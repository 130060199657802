import validator from 'validator';

function check(value) {
    if (value === undefined || value === null) {
        return true;
    } else if (String(value).length < 1) {
        return true;
    } else if (validator.isEmpty(String(value))) {
        return true;
    } else return false;
}

const validate = (data) => {
    let errors = {};

    const { email, password } = data;

    if (check(email)) errors.email = '이메일 필요.';

    if (!validator.isEmail(email) && check(email) === false) {
        errors.email = '잘못된 이메일 주소';
    }

    if (check(password)) errors.password = '비밀번호 필요.';

    return {
        isInvalid: Object.keys(errors).length > 0,
        error: errors,
    };
};

export { validate };
