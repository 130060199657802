import { createRouter, createWebHistory } from 'vue-router';
import { Token } from '@/service/Token';

function guest(to, from, next) {
    const token = new Token();
    token.hasToken().then((tkn) => {
        if (tkn) {
            next({
                name: 'dashboard',
            });
        } else next();
    });
}

async function guard(to, from, next) {
    const tokenUtil = new Token();
    try {
        const { token } = await tokenUtil.getWithExpiry('user');
        if (!token) {
            next({ name: 'login' });
            return;
        }
        const { type } = tokenUtil.parseJwt(token);
        if (type !== 'USER_TYPE_05') throw new Error('관리자 아이디로 로그인해주세요.', { cause: 'Not an admin user' });
        next();
    } catch (error) {
        alert(error.message);
        tokenUtil.remove('user');
        tokenUtil.remove('refreshToken');
        next({
            name: 'login',
        });
        return;
    }
    /* tokenUtil.hasToken().then(async (tkn) => {
        if (tkn) {
            next();
        } else {
            next({
                name: 'login',
            });
        }
    }); */
}

const routes = [
    {
        path: '/',
        name: 'dashboard',
        exact: true,
        component: () => import('@/pages/Dashboard.vue'),
        beforeEnter: guard,
        redirect: '/MemberManagement',
        meta: {
            menu: '회원 관리',
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/Login.vue'),
        beforeEnter: guest,
    },

    // There is no need to implement
    {
        path: '/error',
        name: 'error',
        component: () => import('@/pages/Error.vue'),
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('@/pages/NotFound.vue'),
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('@/pages/Access.vue'),
    },

    //Member Management Start
    {
        path: '/MemberManagement',
        name: 'MemberManagements',
        meta: {
            menu: '회원 관리',
        },
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/MemberManagement/MemberManageList.vue'),
    },
    {
        path: '/add-member',
        name: 'AddMember',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/MemberManagement/AddMember.vue'),
        meta: {
            menu: '회원 관리',
        },
    },
    {
        path: '/edit-member/:id/company/:companyId',
        name: 'editmember',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/MemberManagement/EditMember.vue'),
        meta: {
            menu: '회원 관리',
        },
    },
    {
        path: '/view-member/:id',
        name: 'viewmember',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/MemberManagement/ViewMember.vue'),
        meta: {
            menu: '회원 관리',
        },
    },

    //Corporate Management Start
    {
        path: '/CorporateManagement',
        name: 'CorporateManagements',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/CorporateManagement/CorporateManagementList.vue'),
        meta: {
            menu: '기업 관리',
        },
    },
    {
        path: '/add-supply-corporate/:userId',
        name: 'addsupplycorporate',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/CorporateManagement/AddSupplyCorporate.vue'),
        meta: {
            menu: '기업 관리',
        },
    },
    {
        path: '/edit-supply-corporate/:id',
        name: 'editsupplycorporate',
        beforeEnter: guard, // guard are requiredViewDemandVue
        component: () => import('@/pages/MemberManagement/CorporateManagement/EditSupplyCorporate.vue'),
        meta: {
            menu: '기업 관리',
        },
    },
    {
        path: '/view-supply-corporate/:id',
        name: 'viewsupplycorporate',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/CorporateManagement/ViewSupplyCorporate.vue'),
        meta: {
            menu: '기업 관리',
        },
    },

    {
        path: '/add-demand-company/:userId',
        name: 'adddemand',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/CorporateManagement/AddDemand.vue'),
        meta: {
            menu: '기업 관리',
        },
    },

    {
        path: '/edit-demand-company/:id',
        name: 'editdemandcompany',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/CorporateManagement/EditDemand.vue'),
        meta: {
            menu: '기업 관리',
        },
    },
    {
        path: '/view-demand-company/:id',
        name: 'viewdemandcompany',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/CorporateManagement/ViewDemand.vue'),
        meta: {
            menu: '기업 관리',
        },
    },

    // Mentor management routes
    {
        path: '/mentor-management',
        name: 'MentorManagements',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/MentorManagement/MentorList.vue'),
        meta: {
            menu: '멘토 관리',
        },
    },
    {
        path: '/add-mentor-management',
        name: 'AddMentorManagement',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/MentorManagement/MentorAdd.vue'),
        meta: {
            menu: '멘토 관리',
        },
    },
    {
        path: '/edit-mentor-management/:id',
        name: 'EditMentorManagement',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/MentorManagement/MentorEdit.vue'),
        meta: {
            menu: '멘토 관리',
        },
    },
    {
        path: '/mentor-details/:id',
        name: 'DetailsMentorManagement',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/MentorManagement/MentorDetails.vue'),
        meta: {
            menu: '멘토 관리',
        },
    },
    // 기업 상세 관리
    {
        path: '/company-details',
        name: '기업상세관리',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MemberManagement/CompanyDetailManagement/CompanyDetailList.vue'),
        meta: {
            menu: '기업 상세 관리',
        },
    },
    // Smart Factory routes
    {
        path: '/smart-factory',
        name: 'SmartFactorys',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SmartFactory/SmartFactoryList.vue'),
        meta: {
            menu: '스마트공장',
        },
    },
    {
        path: '/add-smart-factory',
        name: 'AddSmartFactory',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SmartFactory/SmartFactoryAdd.vue'),
        meta: {
            menu: '스마트공장',
        },
    },
    {
        path: '/edit-smart-factory/:id',
        name: 'EditSmartFactory',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SmartFactory/SmartFactoryEdit.vue'),
        meta: {
            menu: '스마트공장',
        },
    },
    {
        path: '/smart-factory-detail/:id',
        name: 'DetailSmartFactory',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SmartFactory/SmartFactoryDetail.vue'),
        meta: {
            menu: '스마트공장',
        },
    },

    // About the supplier
    {
        path: '/supplier-introduction',
        name: 'SupplierIntroductions',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SupplierIntroduction/SupplierIntroductionList.vue'),
        meta: {
            menu: '공급기업 소개',
        },
    },
    {
        path: '/add-supplier-introduction',
        name: 'AddSupplierIntroduction',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SupplierIntroduction/SupplierIntroductionAdd.vue'),
        meta: {
            menu: '공급기업 소개',
        },
    },
    {
        path: '/edit-supplier-introduction/:id',
        name: 'editSupplierIntroduction',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SupplierIntroduction/SupplierIntroductionEdit.vue'),
        meta: {
            menu: '공급기업 소개',
        },
    },
    {
        path: '/supplier-detail/:id',
        name: 'DetailSupplierIntroduction',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SupplierIntroduction/SupplierIntroductionDetail.vue'),
        meta: {
            menu: '공급기업 소개',
        },
    },
    //Best Practices routes
    {
        path: '/best-practices',
        name: 'BestPracticess',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/BestPractices/BestPracticesList.vue'),
        meta: {
            menu: '우수사례',
        },
    },
    {
        path: '/view-best-practices/:id',
        name: 'ViewBestPractices',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/BestPractices/ViewBestPractices.vue'),
        meta: { menu: '우수사례' },
    },
    {
        path: '/add-best-practices',
        name: 'AddBestPractices',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/BestPractices/AddBestPractices.vue'),
        meta: { menu: '우수사례' },
    },
    {
        path: '/edit-best-practices/:id',
        name: 'EditBestPractices',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/BestPractices/EditBestPractices.vue'),
        meta: { menu: '우수사례' },
    },

    //Smart Devices routes
    {
        path: '/smart-devices',
        name: 'smartdevicess',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SmartDevices/SmartDevicesList.vue'),
        meta: { menu: '스마트 디바이스' },
    },
    {
        path: '/add-smart-devices',
        name: 'addsmartdevicess',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SmartDevices/AddSmartDevices.vue'),
        meta: { menu: '스마트 디바이스' },
    },
    {
        path: '/view-smart-device/:id',
        name: 'ViewSmartDevices',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SmartDevices/ViewSmartDevice.vue'),
        meta: { menu: '스마트 디바이스' },
    },
    {
        path: '/edit-smart-device/:id',
        name: 'EditSmartDevices',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SmartDevices/EditSmartDevice.vue'),
        meta: { menu: '스마트 디바이스' },
    },
    //SimpleLevelDiagnosis routes
    {
        path: '/simple-level-diagnosis',
        name: 'SimpleLevelDiagnosis',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SimpleLevelDiagnosis/SimpleLevelDiagnosisList.vue'),
        meta: { menu: '간편수준진단 관리' },
    },
    {
        path: '/view-simple-level-diagnosis/:id',
        name: 'ViewSimpleLevelDiagnosis',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SimpleLevelDiagnosis/ViewSimpleLevelDiagnosis.vue'),
        meta: { menu: '간편수준진단 관리' },
    },
    {
        path: '/add-simple-level-diagnosis',
        name: 'AddSimpleLevelDiagnosis',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SimpleLevelDiagnosis/AddSimpleLevelDiagnosis.vue'),
        meta: { menu: '간편수준진단 관리' },
    },
    {
        path: '/edit-simple-level-diagnosis/:id',
        name: 'EditSimpleLevelDiagnosis',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SimpleLevelDiagnosis/EditSimpleLevelDiagnosis.vue'),
        meta: { menu: '간편수준진단 관리' },
    },

    //SimpleLevelDiagnosisReport
    {
        path: '/simple-level-diagnosis-report',
        name: 'SimpleLevelDiagnosisReport',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/SimpleLevelDiagnosisReport/AddDiagnosisReport.vue'),
        meta: { menu: '간편수준진단 보고서' },
    },

    //Menu Management
    {
        path: '/menu-management',
        name: 'MenuManagement',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MenuManagement/MenuList.vue'),
        meta: { menu: '메뉴 관리' },
    },
    //common code Management
    {
        path: '/common-code-list',
        name: 'CommonCodeList',
        beforeEnter: guard, // guard are required src\pages\CommonCodeManagement\CommonCodeList.vue
        component: () => import('@/pages/CommonCodeManagement/CommonCodeList.vue'),
        meta: { menu: '공통코드관리' },
    },
    {
        path: '/common-code-list/details/:id',
        name: 'CommonCodeDetails',
        beforeEnter: guard, // guard are required src\pages\CommonCodeManagement\CommonCodeList.vue
        component: () => import('@/pages/CommonCodeManagement/CommonCodeDetails.vue'),
        meta: { menu: '공통코드관리' },
    },
    {
        path: '/common-code-list/register',
        name: 'CommonCodeRegister',
        beforeEnter: guard, // guard are required src\pages\CommonCodeManagement\CommonCodeList.vue
        component: () => import('@/pages/CommonCodeManagement/CommonCodeRegister.vue'),
        meta: { menu: '공통코드관리' },
    },
    {
        path: '/common-code-list/edit/:id',
        name: 'CommonCodeEdit',
        beforeEnter: guard, // guard are required src\pages\CommonCodeManagement\CommonCodeList.vue
        component: () => import('@/pages/CommonCodeManagement/CommonCodeRegister.vue'),
        meta: { menu: '공통코드관리' },
    },
    //Calculation of government subsidy
    {
        path: '/gov-subsidy',
        name: 'CalculationOfGovernmentSubsidy',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/GovernmentSubsidy/GovernmentSubsidy.vue'),
        meta: { menu: '정부보조금 계산 관리' },
    },
    {
        path: '/add-central-gov-subsidy',
        name: 'AddCentralCalculationOfGovernmentSubsidy',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/GovernmentSubsidy/AddCentralGovernmentSubsidy.vue'),
        meta: { menu: '정부보조금 계산 관리' },
    },
    {
        path: '/add-local-gov-subsidy',
        name: 'AddLocalCalculationOfGovernmentSubsidy',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/GovernmentSubsidy/AddLocalGovernmentSubsidy.vue'),
        meta: { menu: '정부보조금 계산 관리' },
    },
    {
        path: '/local-gov-subsidy/:id',
        name: 'LocalCalculationOfGovernmentSubsidy',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/GovernmentSubsidy/LocalGovSubsidy.vue'),
        meta: { menu: '정부보조금 계산 관리' },
    },
    {
        path: '/central-gov-subsidy/:id',
        name: 'CentralCalculationOfGovernmentSubsidy',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/GovernmentSubsidy/CentralGovSubsidy.vue'),
        meta: { menu: '정부보조금 계산 관리' },
    },
    {
        path: '/edit-local-gov-subsidy/:id',
        name: 'EditLocalCalculationOfGovernmentSubsidy',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/GovernmentSubsidy/EditLocalGovernmentSubsidy.vue'),
        meta: { menu: '정부보조금 계산 관리' },
    },
    {
        path: '/edit-central-gov-subsidy/:id',
        name: 'EditCentralCalculationOfGovernmentSubsidy',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/GovernmentSubsidy/EditCentralGovernmentSubsidy.vue'),
        meta: { menu: '정부보조금 계산 관리' },
    },

    //notice
    {
        path: '/NoticeManagement',
        name: 'NoticeManagement',
        meta: {
            menu: '공지사항',
        },
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/NoticeManagement/NoticeList.vue'),
    },
    {
        path: '/view-notice/:id',
        name: 'viewnotice',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/NoticeManagement/ViewNotice.vue'),
        meta: {
            menu: '공지사항',
        },
    },
    {
        path: '/add-notice',
        name: 'AddNotice',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/NoticeManagement/AddNotice.vue'),
        meta: {
            menu: '공지사항',
        },
    },
    {
        path: '/edit-Notice/:id',
        name: 'editNotice',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/NoticeManagement/EditNotice.vue'),
        meta: {
            menu: '공지사항',
        },
    },
    //one-one-inquiry
    {
        path: '/oneone-inquiry',
        name: 'OneOneInquiryList',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/OneOneInquiry/OneOneInquiry.vue'),
    },
    {
        path: '/view-one-one/:id',
        name: 'ViewOneOneInquiry',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/OneOneInquiry/ViewOneOneInquiry.vue'),
    },
    //Notification History
    {
        path: '/notification-history',
        name: 'NotificationHistoryList',
        beforeEnter: guard, //guard is required
        component: () => import('@/pages/NotificationManagement/NotificationHistory/NotificationHistoryList.vue'),
    },
    {
        path: '/reply-one-one/:id',
        name: 'ReplyOneOneInquiry',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/OneOneInquiry/ReplyOneOneInquiry.vue'),
    },
    //Mail Management
    {
        path: '/mail-management',
        name: 'MailManagementList',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/NotificationManagement/MailManagement/listAndSearch.vue'),
    },
    {
        path: '/mail-management/shipment/:alarm_id',
        name: 'MailManagementShipmentHistory',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/NotificationManagement/MailManagement/shipmentHistory.vue'),
    },
    {
        path: '/create-mail',
        name: 'CreateMail',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/NotificationManagement/MailManagement/createMail.vue'),
    },
    {
        path: '/edit-mail/:id',
        name: 'EditMail',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/NotificationManagement/MailManagement/editMail.vue'),
    },
    //Text Management
    {
        path: '/text-management',
        name: 'TextManagementList',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/NotificationManagement/TextManagement/listAndSearch.vue'),
    },
    {
        path: '/text-management/shipment/:alarm_id',
        name: 'TextManagementListShipment',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/NotificationManagement/TextManagement/shipmentHistory.vue'),
    },
    {
        path: '/create-text',
        name: 'CreateText',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/NotificationManagement/TextManagement/createText.vue'),
    },
    {
        path: '/edit-text/:id',
        name: 'EditText',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/NotificationManagement/TextManagement/editText.vue'),
    },
    //Faq
    {
        path: '/faq',
        name: 'FaqSearchList',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/Faq/FaqSearchList.vue'),
    },
    {
        path: '/view-faq/:id',
        name: 'ViewFaq',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/Faq/ViewFaq.vue'),
    },
    {
        path: '/edit-faq/:id',
        name: 'EditFaq',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/Faq/EditFaq.vue'),
    },
    {
        path: '/add-faq',
        name: 'AddFaq',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/Faq/AddFaq.vue'),
    },
    {
        path: '/video-to-simple-report',
        name: '전시회 동영상 및 진단 연결',
        beforeEnter: guard,
        component: () => import('@/pages/ContentManagement/Fair/VideoToSimpleReport.vue'),
    },
    // Statistics Management
    {
        path: '/report-result-history',
        name: 'reportResultHistory',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/StatisticsManagement/SimpleReport/HistoryList.vue'),
        meta: { menu: '간편수준진단 이력' },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'err',
        redirect: '/error',
    },
    // mentoring management
    {
        path: '/edit-mentoring/:company_id/:id?',
        name: 'EditMentoringManagement',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/MentoringManagement/EditMentoringManagement.vue'),
    },
    {
        path: '/mentoring-management',
        name: 'MentoringManagementList',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/MentoringManagement/searchAndList.vue'),
    },
    //result report
    {
        path: '/CompanyOverviewResult/:type/:company_name/:mentoring_id/:result_id/:company_id?',
        name: 'CompanyOverviewResult',
        component: () => import('@/pages/BusinessManagement/MentoringManagement/ResultReport/ResultReport.vue'),
    },
    {
        path: '/SmartFactoryDiagnosisResult/:type/:company_name/:mentoring_id/:result_id/:company_id?',
        name: 'SmartFactoryDiagnosisResult',
        component: () => import('@/pages/BusinessManagement/MentoringManagement/ResultReport/ResultReport.vue'),
    },
    {
        path: '/OnSiteLevelDiagnosisResult/:type/:company_name/:mentoring_id/:result_id/:company_id?',
        name: 'OnSiteLevelDiagnosisResult',
        component: () => import('@/pages/BusinessManagement/MentoringManagement/ResultReport/ResultReport.vue'),
    },
    {
        path: '/DirectionOfImprovementResult/:type/:company_name/:mentoring_id/:result_id/:company_id?',
        name: 'DirectionOfImprovementResult',
        component: () => import('@/pages/BusinessManagement/MentoringManagement/ResultReport/ResultReport.vue'),
    },
    {
        path: '/ContentsOfPromotionResult/:type/:company_name/:mentoring_id/:result_id/:company_id?',
        name: 'ContentsOfPromotionResult',
        component: () => import('@/pages/BusinessManagement/MentoringManagement/ResultReport/ResultReport.vue'),
    },
    {
        path: '/HWequipment/:result_id',
        name: 'HWequipment',
        component: () => import('@/pages/BusinessManagement/MentoringManagement/ResultReport/HWequipment.vue'),
    },
    {
        path: '/GoalKPIs/:result_id',
        name: 'GoalKPIs',
        component: () => import('@/pages/BusinessManagement/MentoringManagement/ResultReport/GoalKPIs.vue'),
    },

    // mentor payment status
    {
        path: '/mentor-payment-status',
        name: 'MentorPaymentStatus',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/MentorPayment/searchAndList.vue'),
    },

    // BusinessProgressManagement
    {
        path: '/business-pogress',
        name: '진행사업관리',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/BusinessProgressManagement/BusinessOnGoingList.vue'),
    },
    {
        path: '/business-pogress/:id',
        name: 'EditBusinessProgressManagement',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/BusinessProgressManagement/EditBusinessProgressManagement.vue'),
    },
    {
        path: '/business-pogress-add/:id',
        name: 'AddBusinessProgressManagement',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/BusinessProgressManagement/AddBusinessProgressManagement.vue'),
    },
    // supplier recomendation
    {
        path: '/supplier-recommendation',
        name: 'SupplierRecommendation',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/SupplierRecommendation/searchAndList.vue'),
    },
    {
        path: '/supplier-recommendation/:id',
        name: 'EditSupplierRecommendation',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/SupplierRecommendation/EditSupplierRecommendation.vue'),
    },
    // winwin-business management
    {
        path: '/winwin-business-management',
        name: '상생 신청 관리',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/WinwinBusinessManagement/WinWinManagementList.vue'),
        meta: { menu: '상생 신청 목록' },
    },
    {
        path: '/winwin-business-management/:id',
        name: '상생 신청 조회',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/WinwinBusinessManagement/WinwinBusinessView.vue'),
        meta: { menu: '상생 신청 조회' },
    },

    // device management
    {
        path: '/device-management',
        name: 'DeviceManagementList',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/SmartDevice/DeviceManagement/searchAndList.vue'),
    },
    {
        path: '/add-device-management',
        name: 'AddDeviceManagement',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/SmartDevice/DeviceManagement/AddDeviceManagement.vue'),
    },
    {
        path: '/device-management/:id',
        name: 'EditDeviceManagement',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/SmartDevice/DeviceManagement/EditDeviceManagement.vue'),
    },
    //delivery status
    {
        path: '/delivery-status',
        name: 'DeliveryStatusList',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/SmartDevice/DeliveryStatus/DeliveryStatusList.vue'),
    },
    /* {
        path: '/delivery-status/:id/:param',
        name: 'EditDeliveryStatusDetails',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/SmartDevice/DeliveryStatus/EditDeliveryStatusDetails.vue'),
    }, */
    //sum delivery status
    {
        path: '/delivery-status/:id/:param',
        name: 'SumDeliveryStatusDetails',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/SmartDevice/DeliveryStatus/SumDeliveryStatusDetails.vue'),
    },

    // qoute management
    {
        path: '/qoute-management',
        name: 'QouteManagementList',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/SmartDevice/QouteManagement/searchAndList.vue'),
    },
    {
        path: '/edit-qoute/:id',
        name: 'QouteManagementEdit',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/SmartDevice/QouteManagement/editQoute.vue'),
    },
    //Terms And Conditions routes
    {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MasterManagement/TermsAndConditionsManagement/TermsAndConditionsList.vue'),
        meta: { menu: '마스터 관리' },
    },
    {
        path: '/view-terms-and-conditions/:id',
        name: 'ViewTermsAndConditions',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MasterManagement/TermsAndConditionsManagement/ViewTermsAndConditions.vue'),
        meta: { menu: '마스터 관리' },
    },
    {
        path: '/add-terms-and-conditions',
        name: 'AddTermsAndConditions',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MasterManagement/TermsAndConditionsManagement/AddTermsAndConditions.vue'),
        meta: { menu: '마스터 관리' },
    },
    {
        path: '/edit-terms-and-conditions/:id',
        name: 'EditTermsAndConditions',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MasterManagement/TermsAndConditionsManagement/EditTermsAndConditions.vue'),
        meta: { menu: '마스터 관리' },
    },
    // Manager routes
    {
        path: '/manager',
        name: 'Manager',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MasterManagement/ManagerManagement/ManagerList.vue'),
        meta: { menu: '마스터 관리' },
    },
    {
        path: '/view-manager/:id',
        name: 'ViewManager',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MasterManagement/ManagerManagement/ViewManager.vue'),
        meta: { menu: '마스터 관리' },
    },
    {
        path: '/add-manager',
        name: 'AddManager',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MasterManagement/ManagerManagement/AddManager.vue'),
        meta: { menu: '마스터 관리' },
    },
    {
        path: '/edit-manager/:id',
        name: 'EditManager',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/MasterManagement/ManagerManagement/EditManager.vue'),
        meta: { menu: '마스터 관리' },
    },
    {
        path: '/add-popup',
        name: 'AddPopUp',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/PopUpManagement/AddPopUp.vue'),
    },
    {
        path: '/edit-popup/:id',
        name: 'EditPopUp',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/PopUpManagement/EditPopUp.vue'),
    },
    {
        path: '/popup',
        name: 'PopUp',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/ContentManagement/PopUpManagement/PopUpList.vue'),
    },
    {
        path: '/satisfactionManagement',
        name: 'satisfactionManagement',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/SatisfactionManagement/SatisfactionQuestionList.vue'),
        meta: { menu: '만족도조사 문항관리' },
    },
    {
        path: '/satisfactionQuestionEdit/:id',
        name: 'satisfactionQuestionEdit',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/SatisfactionManagement/SatisfactionQuestionEdit.vue'),
        meta: { menu: '만족도조사 문항관리' },
    },
    {
        path: '/satisfactionQuestionAdd',
        name: 'satisfactionQuestionAdd',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/SatisfactionManagement/SatisfactionQuestionAdd.vue'),
        meta: { menu: '만족도조사 문항관리' },
    },
    {
        path: '/satisfactionResult',
        name: 'satisfactionResult',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/SatisfactionResult/SatisfactionResultList.vue'),
        meta: { menu: '만족도조사 결과' },
    },
    {
        path: '/satisfactionResultScore/:satisfactionCode/:id/:user_id',
        name: 'satisfactionResultScore',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/SatisfactionResult/SatisfactionResultScore.vue'),
        meta: { menu: '만족도조사 결과' },
    },
    {
        path: '/satisfactionResultStatistics',
        name: 'satisfactionResultStatistics',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/BusinessManagement/SatisfactionResult/SatisfactionResultStatistics.vue'),
        meta: { menu: '만족도조사 결과' },
    },
    {
        path: '/transactionManagement',
        name: 'transactionManagement',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/UsedFacility/TransactionManagement/TransactionManagementList.vue'),
        meta: { menu: '거래 관리' },
    },
    {
        path: '/transactionManagementSale/:id/:divisionCode',
        name: 'transactionManagementSaleDetail',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/UsedFacility/TransactionManagement/TransactionManagementSaleDetail.vue'),
        meta: { menu: '거래 관리 상세' },
    },
    {
        path: '/transactionManagementBuy/:id/:divisionCode',
        name: 'transactionManagementBuyDetail',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/UsedFacility/TransactionManagement/TransactionManagementBuyDetail.vue'),
        meta: { menu: '거래 관리 상세' },
    },
    {
        path: '/statusManagement',
        name: 'statusManagement',
        beforeEnter: guard, // guard are required
        component: () => import('@/pages/UsedFacility/StatusManagement/StatusManagementList.vue'),
        meta: { menu: '현황' },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return {
            left: 0,
            top: 0,
        };
    },
});

export default router;
