import { API, errCheck } from './API';
import { Token } from './Token';

export class MemberManagement {
    local = new Token();

    async list(obj) {
        try {
            var token = await this.local.getWithExpiry('user').then((r) => r.token);
            var params = new URLSearchParams(obj).toString();
            return await API(token).get(`admin/members?${params}`);
        } catch (error) {
            return errCheck(error);
        }
    }

    async delete(data) {
        try {
            var token = await this.local.getWithExpiry('user').then((r) => r.token);
            return await API(token).delete(`admin/deletemember/${data}`);
        } catch (error) {
            return errCheck(error);
        }
    }

    async add(data) {
        try {
            var token = await this.local.getWithExpiry('user').then((r) => r.token);
            return await API(token).post(`admin/addmember`, data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async edit(id, data) {
        let headers= {
            'Accept-Language': 'ko',
        }
        try {
            var token = await this.local.getWithExpiry('user').then((r) => r.token);
            return await API(token).patch(`admin/editmember/${id}`, data , { headers });
        } catch (error) {
            return errCheck(error);
        }
    }

    async view(member_id) {
        try {
            var token = await this.local.getWithExpiry('user').then((r) => r.token);
            return await API(token).get(`admin/members/${member_id}`);
        } catch (error) {
            return errCheck(error);
        }
    }

    async changePassword(id, data) {
        try {
            var token = await this.local.getWithExpiry('user').then((r) => r.token);
            return await API(token).patch(`admin/members/${id}/change-password`, data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async lookUpAffiliatedCompany(company_id, company_name) {
        try {
            var token = await this.local.getWithExpiry('user').then((r) => r.token);
            return await API(token).get(`admin/affiliated/${company_name}/${company_id}`);
        } catch (error) {
            return errCheck(error);
        }
    }

    async changeAffiliatedCompany(data) {
        try {
            var token = await this.local.getWithExpiry('user').then((r) => r.token);
            return await API(token).put('admin/change-to-affiliate', data);
        } catch (error) {
            return errCheck(error);
        }
    }

    async download(params, data) {
        try {
            var token = await this.local.getWithExpiry('user').then((r) => r.token);
            var searchParams = new URLSearchParams(params).toString();
            return await API(token).post(`admin/memberDownload?${searchParams}`, data, { responseType: "arraybuffer"});
        } catch (error) {
            return errCheck(error);
        }
    }

    async bulkdelete(data) {
        try {
            var token = await this.local.getWithExpiry('user').then((r) => r.token);
            return await API(token).delete(`admin/delete-members`, {data});
            // console.log("companyIds", {companyIds: companyIds});
        } catch (error) {
            return errCheck(error);
        }
    }
}
