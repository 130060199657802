<template>
    <div class="layout-topbar" :style="sidebarStatic ? 'padding-left: 230px' : ''">
        <div class="layout-topbar-wrapper">
            <div class="layout-topbar-left">
                <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
                    <i class="pi pi-bars"></i>
                </a>
                <router-link id="logo-link" class="layout-topbar-logo" to="/">
                    <img :src="origin + '/assets/layout/images/tech-square-logo.svg'" alt="tech-square" />
                </router-link>
            </div>

            <AppMenu
                :layoutMode="layoutMode"
                :sidebarActive="sidebarActive"
                :sidebarStatic="sidebarStatic"
                :menuActive="menuActive"
                :mobileMenuActive="mobileMenuActive"
                @sidebar-mouse-leave="onSidebarMouseLeave"
                @sidebar-mouse-over="onSidebarMouseOver"
                @toggle-menu="onToggleMenu"
                @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
            />

            <div class="layout-topbar-right" :style="!sidebarStatic ? 'margin-left: 60px !important' : ''">
                <span @click="$router.back()"><i style="cursor: pointer; margin-right: 5px" class="fa">&#xf104;</i> {{ topbar($route.name) }}</span>
                <ul class="layout-topbar-actions">
                    <!-- <li>
                        <h6 style="margin-top: 11px">{{ admin_type }}</h6>
                    </li> -->

                    <li ref="profile" class="topbar-item user-profile" :class="{ 'active-topmenuitem fadeInDown': topbarUserMenuActive }">
                        <a style="margin-left: 0">
                            <img :src="`${origin}/assets/layout/images/user.png`" alt="Super Admin" @click="onTopbarUserMenuClick" />
                        </a>
                        <a @click="onTopbarUserMenuClick" class="admin"
                            ><b style="white-space: nowrap">{{ admin_type }}</b></a
                        >
                        <h6 style="margin-top: 11px; cursor: pointer; margin-left: 5px" @click="onTopbarUserMenuClick"><i style="font-size: 24px" class="fa">&#xf107;</i></h6>
                        <ul class="fadeInDown">
                            <li @click="viewprofile">
                                <div>
                                    <span>프로필 보기</span>
                                </div>
                            </li>

                            <li @click="changepass">
                                <div>
                                    <span>비밀번호변경</span>
                                </div>
                            </li>
                            <li @click="logout">
                                <div>
                                    <span>로그아웃</span>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import AppMenu from './AppMenu';
import { Token } from '@/service/Token';
import { MemberManagement } from '@/service/MemberManagement.js';
import { inject } from 'vue';
// import LanguageInput from './components/LanguageInput.vue';
export default {
    name: 'AppTopbar',
    data() {
        return {
            origin: window.location.origin,
            admin_type: '',
        };
    },
    emits: [
        'menu-click',
        'menuitem-click',
        'root-menuitem-click',
        'menu-button-click',
        'toggle-menu',
        'right-menubutton-click',
        'sidebar-mouse-over',
        'sidebar-mouse-leave',
        'topbar-search-toggle',
        'topbar-search-click',
        'topbar-search-hide',
        'topbar-usermenu-click',
        'update:searchClick',
    ],
    props: {
        searchActive: Boolean,
        searchClick: Boolean,
        topbarItemClick: Boolean,
        topbarUserMenuActive: Boolean,
        topbarUserMenuClick: Boolean,
        activeTopbarItem: String,
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        topbarTheme: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean,
    },
    created() {
        this.store = inject('store');
        this.MemberManagement = new MemberManagement();
        this.token = new Token();
    },
    unmounted() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    async mounted() {
        await this.getUserName();
    },

    methods: {
        parseJwt(token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(
                window
                    .atob(base64)
                    .split('')
                    .map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );

            return JSON.parse(jsonPayload);
        },
        async getUserName() {
            let userTkn = await this.token.getWithExpiry('user');
            if (userTkn.token && userTkn.token.type === 'USER_TYPE_05') {
                let userId = this.parseJwt(userTkn.token).id;
                this.MemberManagement.view(userId).then((res) => {
                    this.admin_type = res.data.userInfo.name;
                    // console.log(res.data.userInfo.name);
                });
            }
        },
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
        onMenuButtonClick(event) {
            this.$emit('menu-button-click', event);
        },
        onToggleMenu(event) {
            this.$emit('toggle-menu', event);
        },
        onTopbarSearchToggle(event) {
            this.$emit('topbar-search-toggle', event);
            this.onSearchFocus();
        },
        onTopbarSearchClick(event) {
            this.$emit('topbar-search-click', event);
        },
        onInputKeydown(event) {
            const key = event.which;

            //escape, tab and enter
            if (key === 27 || key === 9 || key === 13) {
                this.$emit('topbar-search-hide', event);
            }
        },
        onTopbarUserMenuClick(event) {
            this.$emit('topbar-usermenu-click', event);
        },
        onRightMenuButtonClick(event) {
            this.$emit('right-menubutton-click', event);
        },
        onSidebarMouseOver() {
            this.$emit('sidebar-mouse-over');
        },
        onSidebarMouseLeave() {
            this.$emit('sidebar-mouse-leave');
        },
        logout() {
            let token = new Token();
            token.remove('user');
            token.remove('refreshToken');
            this.$router.push({ name: 'login' });
        },
        changepass() {
            this.$router.push({ name: 'changePassword' });
        },

        viewprofile() {
            this.$router.push({ name: 'ViewProfile' });
        },
        onSearchFocus() {
            if (window.innerWidth >= 576) {
                this.$refs.desktopInput.$el.focus();
            } else {
                this.$nextTick(function () {
                    this.$refs.phoneInput.$el.focus();
                });
            }
        },
        topbar(name) {
            return this.$t(String(name));
        },
    },
    components: {
        AppMenu,
        // LanguageInput,
    },
};
</script>
