import { reactive, readonly} from 'vue';

const state = reactive({});

const mutations = {
    remove() {
        state = null;
    }
}

const getters = {
    getPageInfo(pageName = null) {
        if (pageName) return state[pageName];
        return state;
    },
}

const setters = {
    setPageInfo(pageName, options) {
        state[pageName] = options;
    }
}

export default {
    state: readonly(state),
    mutations,
    getters,
    setters,
};