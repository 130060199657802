import { reactive } from 'vue';

const state = reactive({
    user: {
        userCompanyID: '',
        userID: '',
    },
    flash: {
        display: false,
        data: {},
    },
    specificationCode: {},
    cmmCodeList: {},
});

export default {
    state,
};
